<template>
  <div class="login-section-outer row">
    <div class="col-lg-6 col-md-6 col-12 login-banner-outer ps-0">
      <div class="
          d-flex
          flex-column
          min-vh-100
          justify-content-center
          align-items-center
          custom-baner-logo-height-mb
        ">
        <img v-if="this.client_info.maa59 == null || this.client_info.maa59 == ''" class="img-fluid"
          src="/assets/images/login-banner.jpg" alt="partylogo" />
        <img v-if="this.client_info.maa59 != null && this.client_info.maa59 != ''" class="img-fluid" :src="party_banner"
          alt="partylogo" />
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 login-form-outer-border">
      <div class="login-form-outer d-flex align-items-center">
        <div class="login-form-inner">
          <div class="login-header-image text-center">
            <div v-if="onchangepartylogo == ''">
              <img v-if="this.client_info.maa47 == null || this.client_info.maa47 == ''"
                src="/assets/images/meraneta-logo-black.png" class="img-fluid" alt="party-head-img" width="146" />
              <img v-if="this.client_info.maa47 != null && this.client_info.maa47 != ''" :src="party_logo"
                class="img-fluid" alt="party-head-img" width="146" height="146" />
            </div>
            <div v-else>
              <img :src="this.onchangepartylogo" class="rounded-circle login-img-border" alt="party-head-img"
                width="146" height="146" />
            </div>
          </div>
          <div class="login-header-sublabel">{{ this.onchangepartyname }}</div>
          <div class="login-header-label">Login</div>
          <div class="login-form-layout">
            <div class="row">
              <div class="col-12">
                <div class="custom-form-group">
                  <label for="formUsernameInput" class="form-label">Mobile No.<span class="text-danger">*</span></label>
                  <input type="text" v-model="username" class="form-control" id="formUsernameInput"
                    placeholder="Enter Your Mobile No." autocomplete="off" maxlength="10" @paste="onPasteMobile" @keypress="onlyNumberMobile"
                    tabindex="1" />
                  <div class="custom-error" v-if="v$.username.$error">
                    {{ v$.username.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group">
                  <label for="formPasswordInput" class="form-label d-flex justify-content-between">
                    <span>Password<span class="text-danger">*</span></span><span>
                      <button class="nav-link custom-forgotpassword-label-btn" id="teamvoter-report-tab"
                        data-bs-toggle="tab" data-bs-target="#teamvoterreport" type="button" role="tab"
                        aria-controls="customer-details" aria-selected="false"
                        @click="redirectforgotpasswardurl()">Forgot
                        Password?</button>
                    </span></label>
                  <div class="input-group">
                    <input class="form-control custom-input-passward" placeholder="Enter Password" aria-label="Password"
                      v-model="password" :type="passwordVisibility" maxlength="12" aria-describedby="formPasswordInput"
                      autocomplete="off" v-on:keyup.enter="login()" tabindex="2" />
                    <span class="input-group-text" id="formPasswordInput">
                      <button @click="showPasswordType()" v-if="passwordVisibility == 'password'"
                        class="btn btn-link login-password-type">
                        <i class="pi pi-eye-slash"></i>
                      </button>
                      <button @click="hidePasswordType()" v-if="passwordVisibility == 'text'"
                        class="btn btn-link login-password-type">
                        <i class="pi pi-eye"></i>
                      </button>
                    </span>
                  </div>
                  <div class="custom-error" v-if="errormsg">
                    {{ errormsg }}
                  </div>
                  <div class="custom-error" v-if="errormsg == '' && v$.password.$error">
                    {{ v$.password.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group">
                  <button class="btn login-submit-btn" @click="login()" type="submit" tabindex="3"
                    :disabled="showloader">
                    <span v-if="!showloader">Login</span>
                    <div class="
                        spinner-border
                        text-light
                        custom-spinner-loader-btn
                      " role="status" v-if="showloader"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      username: "",
      password: "",
      passwordVisibility: "password",
      showloader: false,
      submitted: false,
      errormsg: '',
      client_info: "",
      party_logo: "",
      baseUrl: window.location.origin,
      party_banner: "",
      onchangepartylogo: "",
      onchangepartyname: "",
      partyloginList: [],
      client_path: '',
      localstorageimgpath: '',
    };
  },
  watch: {
    username() {
      this.v$.$validate();
      if (this.username.length == 10) {
        this.getLoginUserByMobileNumber(this.username);
      } else {
        this.onchangepartylogo = "";
        this.onchangepartyname = '';
      }
    },
    password() {
      this.v$.$validate();
    },
  },
  validations() {
    return {
      username: { required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage('Enter vaild Mobile No.', minLength(10)) },
      password: {
        required: helpers.withMessage("Please enter password", required),
        maxLength: helpers.withMessage(
          "Password should be max 12 length",
          maxLength(12)
        ),
        // minLength: helpers.withMessage(
        //   "Password should be min 6 length",
        //   minLength(6)
        // ),
      },
    };
  },

  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    const jsonData = queryParams.get('data');

    if (jsonData) {
      try {
        const decodedData = decodeURI(jsonData);
        const receivedData = JSON.parse(decodedData);
        if (receivedData) {
          localStorage.setItem("user_reference", receivedData.user_reference || "");
          localStorage.setItem("user", JSON.stringify(receivedData.user_data || {}));
          localStorage.setItem("permission_fks", receivedData.permission_fks || "");
          localStorage.setItem("client_info", JSON.stringify(receivedData.client_info || {}));
          localStorage.setItem("LoginAsClientInClient", receivedData.LoginAsClientInClient || "");
          localStorage.setItem("loginUserId", receivedData.loginUserId || "");
          localStorage.setItem("masterbucketname", 'meraneta_admin');
          var currentTime = new Date().toUTCString();
          localStorage.setItem("last_login_time", currentTime);
          this.$router.push("/dashboard");

        }
      } catch (error) {
        console.error("Error decoding or parsing JSON:", error);
      }
    }
    if (localStorage.getItem("user")) {
      this.$router.push("/dashboard");
    }
    // if (localStorage.getItem("user_reference")) {
    //   this.$router.push("/dashboard");
    // } else if (!localStorage.getItem("client_info")) {
    //   this.$router.push("/login");
    // }
    this.client_info = JSON.parse(localStorage.client_info);
    if (this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
      this.client_path = this.client_info.maa24;
    } else {
      this.client_path = localStorage.masterbucketname;
    }
    this.party_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/Logo/" + this.client_info.maa6;
    this.party_banner = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyBanner/" + this.client_info.maa59;
    this.localstorageimgpath = this.client_info.maa24;
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    getLoginUserByMobileNumber(e) {
      this.ApiService.getLoginUserByMobileNumber({ login_id: e }).then((data) => {
        if (data.status == 200) {
          this.partyloginList = data.data;
          if (this.partyloginList.ak90 && this.partyloginList.ak151 == 2) {
            this.onchangepartylogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/voterphoto/" + this.partyloginList.ak90;
          } else if (this.partyloginList.ak33 && this.partyloginList.ak151 == 1) {
            this.onchangepartylogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/aadharphoto/" + this.partyloginList.ak33;
          }
          else {
            this.onchangepartylogo = '';
          }
          this.onchangepartyname = this.partyloginList.ak5;
          this.errormsg = '';
        } else {
          this.errormsg = data.message;
          this.onchangepartylogo = '';
          this.onchangepartyname = '';
        }
      });
    },
    login() {
      this.submitted = true;
      this.v$.$validate();
      let fields = {};
      fields["login_id"] = this.username;
      fields["login_password"] = this.password;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.login(fields).then((data) => {
          if (data.success === true) {
            this.showloader = false;
            var currentTime = new Date().toUTCString();
            localStorage["last_login_time"] = currentTime;
            localStorage["user"] = JSON.stringify(
              data.data.user_data
            );
            localStorage["user_reference"] = data.data.user_reference;
            localStorage["permission_fks"] = JSON.stringify(data.data.permission_fks);
            window.location.href = "/dashboard";
            // var successMsg = data.message;
            // this.$toast.open({
            //   message: successMsg,
            //   type: "success",
            //   duration: 3000,
            //   position: "top-right",
            // });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloader = false;
            this.errormsg = data.message;
          }
        });
      }
      else {
        this.$error;
      }
    },
    showPasswordType() {
      this.passwordVisibility = "text";
    },
    hidePasswordType() {
      this.passwordVisibility = "password";
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formUsernameInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    redirectforgotpasswardurl() {
      localStorage["localusername"] = this.username;
      this.$router.push("/forgotpassword");
    },
  },
};
</script>
